.main {
    background: white;
    width: 100vw;
    height: 130vh;
    position: fixed;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.span {
    --primary: #61dbff;
    color: rgba(0, 0, 0, 0.9);
    text-decoration: none;
    padding: 0 1px;
    box-shadow: inset 0 -6px 0 0 var(--primary);
    transition: box-shadow 0.3s ease-in;

}
.span:hover {
box-shadow: inset 0 -40px 0 0 var(--primary);
}

.span:active {
box-shadow: inset 0 -40px 0 0 var(--primary);
}
