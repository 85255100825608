.home {
    position: fixed;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;
    background: #211e1e;
    overflow-x: hidden;
}
.firstTexts {
    position: relative;
    top: 10%;
}
.first {
    color: rgb(243, 243, 251);
    position: relative;
    width: 100%;
    height: 100%;
    background: #211e1e;
}
.seeAll {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    align-items: center;
    box-sizing: border-box;
    font-family: Montserrat;
    justify-content: flex-end;
    font-size: 16px;
    color: rgb(243, 243, 251);
    font-weight: 500;
    gap: 5px;
}
.txt1 {
    font-family: Montserrat;
    width: 100%;
    /* font-weight: 300; */
    font-size: 23px;
    margin-top: 43px;
    padding-left: 25px;
    line-height: 28px;
    mix-blend-mode: screen;
}
.txt2 {
    font-family: Montserrat;
    padding-left: 25px;
    font-style: normal;
    font-size: 40px;
    font-weight: 500;
    line-height: 37px;
    margin-bottom: 5px;
    mix-blend-mode: screen;
}
.txt3 {
    padding-left: 25px;
    font-family: Montserrat;
    font-size: 20px;
}
.instaBox {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 15px;
}
.instaFlex {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0px 20px;
}
.instaOverlay {
    top: 0px;
    position: absolute;
    z-index: 2;
    background: rgba(1, 27, 64, 0.7);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.vinyl {
    position: relative;
    right: 80px;
    top: calc(100% - 600px);
}
.disc {
    position: absolute;
    width: 191px;
    transform: translate(0%, 0%);
    animation: first 5s infinite linear;
}
.pin {
    top: -110px;
    left: -30px;
    z-index: 1;
    position: absolute;
    height: 220px;
}
.lamba {
    position: absolute;
    bottom: -30px;
    right: 0px;
    width: 308px;
}
.second {
    color: rgb(243, 243, 251);
    position: relative;
    width: 100%;
    height: fit-content;
    background: #211e1e;
}
.centerDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}
.third {
    color: rgb(243, 243, 251);
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    background: #211e1e;
}
.txt4 {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 29px;
    line-height: 45px;
}
.txt4::after {
    content: '';
    width: 100px;
    display: block;
    margin: auto;
    margin-top: 0px;
    border-bottom: 2px solid rgb(243, 243, 251);
}
@keyframes first {
    0% {
      transform: translate(0%, 0%) rotate(0deg);
    }
    100% {
      transform: translate(0%, 0%) rotate(360deg);
    }
  }
.txt5 {
    width: 271px;
    margin: auto;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 98.17%;
    padding-left: 6px;
    padding-top: 18px;
    margin-bottom: 35px;
}
.txt6 {
    font-size: 17px;
    margin-left: 23px;
    font-weight: 600;
    width: 271px;
    margin: auto;
    margin-top: 18px;
}
.txt7 {
    font-family: 'Montserrat';
    font-size: 25px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16.69px;
}
.txt8 {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 45px;
    margin-top: 50px;
}
.txt8::after {
    content: '';
    width: 100px;
    display: block;
    margin: auto;
    margin-top: 0px;
    border-bottom: 2px solid rgb(243, 243, 251);
}
.leftTrack {
    display: flex;
    transition: all .6s ease-in-out;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    transform: translateX(-35%);
    margin-top: 40px;
    margin-bottom: 40px;
}
.rightTrack {
    transition: all .6s ease-in-out;
    margin-top: 40px;
    display: flex;
    margin: auto;
    transform: translateX(50%);
    flex-direction: column;
    margin-bottom: 40px;
}
.sideTrackTxt {
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    line-height: 98.17%;
}
.sideTrackYear {
    font-style: italic;
    font-family: Montserrat;
    font-size: 18px;
    /* font-weight: 100; */
}
.track {
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    box-sizing: border-box;
    padding: 15px;
    width:calc(100% - 55px);
    height: 75px;
    margin: auto;
    font-family: Montserrat;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.trackActive {
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, .2) 0px 54px 55px, rgba(0, 0, 0, .2) 0px -12px 30px, rgba(0, 0, 0, .2) 0px 4px 6px, rgba(0, 0, 0, .2) 0px 12px 13px, rgba(0, 0, 0, .2) 0px -3px 5px;
    height: 75px;
    width:calc(100% - 55px);
    margin: auto;
    font-family: Montserrat;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.subTrack11 {
    display: flex;
    margin-bottom: 10px;
}
.subTrack12 {
    display: flex;
}
.trackTitle {
    font-size: 17px;
    text-transform: uppercase;
    word-spacing: 10px;
    font-weight: 600;
    margin-right: auto;
}
.trackDuration {
    font-weight: bold;
    margin-left: auto;
}
.trackArtiste {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 13px;
    margin-left: 27px;
    margin-top: -5px;
    /* font-weight: 500; */
}
.trackControl {
    margin-left: auto;
    margin-right: 10px;
}
.fourth {
    color: rgb(243, 243, 251);
    position: relative;
    width: 100%;
    height: fit-content;
    background: #211e1e;
}
.eventFlex {
    display: flex;
    width: calc(100% - 40px);
    margin: auto;
    height: fit-content;
    margin-top: 25px;
    margin-bottom: 25px;
}
.eventDate {
    box-shadow: rgba(0, 0, 0, .15) 0px 54px 55px, rgba(0, 0, 0, .15) 0px -12px 30px, rgba(0, 0, 0, .15) 0px 4px 6px, rgba(0, 0, 0, .15) 0px 12px 13px, rgba(0, 0, 0, .15) 0px -3px 5px;
    margin-right: auto;
    height: 50px;
    width: 75px;
    color: rgb(243, 243, 251);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    border-radius: 5px;
}
.eventDate > p:nth-child(1){
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    flex: 1;
    text-align: center;
    display: flex;
    color: rgb(243, 243, 251);
    justify-content: center;
    align-items: center;
}
.eventDate > p:nth-child(2){
    font-size: 15px;
    color: rgb(243, 243, 251);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    font-family: Montserrat;
}
.eventTitle {
    height: 50px;
    color: rgb(243, 243, 251);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 150px;
    margin-right: auto;
}
.eventTitle > p:nth-child(1){
    font-family: Montserrat;
    font-size: 18px;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
}
.eventTitle > p:nth-child(2){
    font-size: 16px;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: Montserrat;
}
.eventButtonCont {
    width: calc(100% - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    position: relative;
    margin-left: auto;
}
.eventButton {
    height: 40px;
    width: 100%;
    font-size: 13px;
    font-family: Montserrat;
    color: rgb(243, 243, 251);
    background: #211e1e;
    border-radius: 5px;
    white-space: nowrap;
}
.eventBuy {
    box-shadow: rgba(0, 0, 0, .15) 0px 54px 55px, rgba(0, 0, 0, .15) 0px -12px 30px, rgba(0, 0, 0, .15) 0px 4px 6px, rgba(0, 0, 0, .15) 0px 12px 13px, rgba(0, 0, 0, .15) 0px -3px 5px;
    height: 40px;
    width: 100%;
    font-size: 13px;
    font-family: Montserrat;
    color: rgb(243, 243, 251);
    background: #211e1e;
    border-radius: 5px;
    white-space: nowrap;
}
.eventBuy:active {
    border: 2px solid #c3a34b;
}
.fifth {
    color: rgb(243, 243, 251);
    height: fit-content;
}
.fourthy {
    color: rgb(243, 243, 251);
    height: fit-content;
}
.blogCont { 
    width: calc(100% - 40px);
    margin: auto;
    gap: 12px;
    height: fit-content;
    display: flex;
    /* padding: 20px; */
    color: rgb(243, 243, 251);
    margin-top: 30px;
    margin-bottom: 40px;
    font-family: Montserrat;
    flex-direction: column;
}
.blogDate {
    font-size: 13px;
    margin-bottom: -6px;
}
.blogHeader {
    text-transform: capitalize;
    width: 100%;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 500;
    
}
.blogExcerpt {
    width: 100%;
    height: fit-content;
    font-size: 16px;
}
.blogButton {
   font-weight: 500;
   font-size: 14px;
   display: flex;
   font-family: Montserrat;
   box-shadow: none;
   background: none;
   color: rgb(243, 243, 251);
   gap: 5px;
   justify-content: flex-start;
   align-items: center; 
}
.footerCont {
    transition: all 1s ease-in-out;
}