.footerCont {
    color: white;
    width: 100%;
    box-shadow: rgba(0, 0, 0, .2) 0px 54px 55px, rgba(0, 0, 0, .2) 0px -12px 30px, rgba(0, 0, 0, .2) 0px 4px 6px, rgba(0, 0, 0, .2) 0px 12px 13px, rgba(0, 0, 0, .2) 0px -3px 5px;
    font-family: Montserrat;
    height: 290px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 0px;
    background: linear-gradient(88.41deg, #1D1B1B 3.73%, rgba(130, 126, 126, 0.06) 101.12%);
}
.textCenter {
    position: relative;
    top: -15px;
    text-align: center;
}
.footerLogo {
    width: 263px;
    height: 93px;
}
.iconSet {
   display: flex; 
   gap: 20px;
   position: relative;
   top: -20px;
   font-size: 35px;
}
.iconSet > a {
    margin: 0px 10px;
}
