html, body {
  background: #151313;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  font-display: swap;
  font-family: Montserrat, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}
*{
  -webkit-tap-highlight-color: transparent;
  margin:0;
  padding: 0;
  border:0;
  box-sizing:border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.menuIn {
  transform: translateX(0);
}

.menuOut {
  transform: translateX(-100%);
}

.comeBack {
  transform: translateX(0%);
}

a {
  text-decoration: none;
  color: white;
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
