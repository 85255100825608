.hero {
height:100%;
width:100%;
margin:0px;
border:0px;
position: fixed;
z-index: 5;
background-color: black;
overflow: hidden;
padding:2px;    
}
.img {
width: 150px;
opacity: .8;
}
.container {
display:flex;
position: relative;
justify-content:center;
align-items: center;
width: 100%;
height:100%;
box-sizing: border-box;
background-color: black;
border: 2px solid;
box-sizing: border-box;
text-align: center;
padding-bottom:30px;
padding-top:30px;
}
.roller{
position: absolute;
width: 1600vw;
height: 20vh;
border:2px solid ;
display:flex;
opacity: .3;
justify-content:center;
align-items: center; 
background: linear-gradient(#ce2222, white);
animation: animate 2s linear infinite;
z-index: -1;
}
@keyframes animate {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}