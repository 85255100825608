 .nav {
    backdrop-filter: blur(4px);
    display: flex;
    position: fixed;
    height: 77px;
    width: 100%;
    background: linear-gradient(
      88.41deg,
      #2d2929 3.73%,
      rgba(130, 126, 126, 0.06) 101.12%
    );
    z-index: 5;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  }
  .menu {
    display: flex;
    transition: all .5s ease;
    width: 100%;
    height: 100%;
    z-index: 6;
    position: fixed;
    background: linear-gradient(
      88.41deg,
      #2d2929 3.73%,
      rgba(130, 126, 126) 101.12%
    );
    background: rgb(195, 163, 75, .9);
    justify-content: center;
    backdrop-filter: blur(10px);
    align-items: center;
    flex-direction: column;
  }
  .menu > p {
    font-size: 64px;
    font-weight: 999;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: -3px;
    font-size: 66px;
  }
  .home {
    background: url(../../public/assets/img/sharky-min.jpeg) 0 /  cover repeat;
    color: white;
    background-size: 100% 100%;
    background-position: -5px 10px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .sounds {
    text-transform: uppercase;
    background: url(../../public/assets/img/lambox.png) 0 /  cover no-repeat;
    color: white;
    background-size: 300px 200px;
    background-position: -35px -60px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .blog {
    text-transform: uppercase;
    background: url(../../public/assets/img/insta3.png) 0 /  cover repeat;
    color: white;
    background-size: 450px 500px;
    background-position: -35px -110px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .merch {
    text-transform: uppercase;
    color: rgb(50, 48, 48);
  }
  .event {
    color: lavender;
  }
 .logo {
    object-fit: contain;
    width: 150px;
    height: 100px;
    position: relative;
    right: 6px;
  }
 .menuIcon {
    width: 40px;
    height: 40px;
  }